.home {
    width: 100vw;
    height: 100vh;
    background-color: white;
}
.header{
    height: calc(10vh - 1px);
    border-bottom: 1px solid black;
}

.routers{
    width: 80%;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    margin:  0 auto;
}
.route-item{
    line-height: 10vh;
    font-family: titlefont;
    font-size: 20px;
}
.content{
    height: 90vh;
    opacity: 0.8;
    background: linear-gradient(to top, #004C97,#82B9F0,#E7F3FF,#FFFFFF);
}
.plant {
    height:12vh;
    object-fit: contain;
    position: absolute;
    top: 35vh;
    left: 38vw;
    transform-origin: center center;
    animation: spin 30s linear infinite;
}

.plant:hover{
    cursor: pointer;
    animation-play-state: paused;
}

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform:translate(-50%, -50%) rotate(360deg);
    }
}

.title {
    font-family: titlefont;
    color: black;
    font-size: 100px;
    position: absolute;
    top: 28vh;
    left:26vw;
    transform: translate(-50%, -50%);
    cursor: default;
}

.sub-title{
    font-family: titlefont;
    color: white;
    font-size: 24px;
    position: absolute;
    top: 46vh;
    left:18.5vw;
    line-height: 30px;
    cursor: default;
}

.footer{
    position: fixed;
    bottom: 0;
    height: 3vh;
    text-align: center;
    width: 100%;
}

.footer span{
    color: #bfbfbf;
}

.footer span:hover{
    color: white;
    cursor: pointer;
}